import type { IGroupProgressOptions, IGroupProgressJSON } from 'o365.pwa.modules.client.steps.GroupProgress.ts';
import { GroupProgress } from 'o365.pwa.modules.client.steps.GroupProgress.ts';

export interface IPropertyConfigSyncProgressJSON extends IGroupProgressJSON {
    propertyConfigSyncHasStarted: boolean;
    propertyConfigSyncHasErrors: boolean;
    propertyConfigSyncHasCompleted: boolean;
}

export interface IPropertyConfigSyncProgressOptions extends IGroupProgressOptions {
    propertyConfigSyncHasStarted?: boolean;
    propertyConfigSyncHasErrors?: boolean;
    propertyConfigSyncHasCompleted?: boolean;
}

export class PropertyConfigSyncProgress extends GroupProgress {
    private _propertyConfigSyncHasErrors: boolean = false;

    public propertyConfigSyncHasStarted: boolean = false;
    public propertyConfigSyncHasCompleted: boolean = false;

    public set propertyConfigSyncHasErrors(newValue: boolean) {
        this._propertyConfigSyncHasErrors = newValue;
    }

    public get propertyConfigSyncHasErrors(): boolean {
        return this.hasError || this._propertyConfigSyncHasErrors;
    }

    get progressStatusPending(): number {
        if (this.hasError) {
            return 0;
        }

        return this.propertyConfigSyncHasStarted ? 100 : 0;
    }

    get progressStatusSuccess(): number {
        if (this.hasError) {
            return 0;
        }

        return this.propertyConfigSyncHasCompleted ? 100 : 0;
    }

    get progressStatusError(): number {
        if (this.hasError) {
            return 100;
        }

        return this.propertyConfigSyncHasErrors ? 100 : 0;
    }

    constructor(options: IPropertyConfigSyncProgressOptions) {
        super(options);

        options.propertyConfigSyncHasStarted && (this.propertyConfigSyncHasStarted = options.propertyConfigSyncHasStarted);
        options.propertyConfigSyncHasErrors && (this.propertyConfigSyncHasErrors = options.propertyConfigSyncHasErrors);
        options.propertyConfigSyncHasCompleted && (this.propertyConfigSyncHasCompleted = options.propertyConfigSyncHasCompleted);
    }

    public toJSON(): IPropertyConfigSyncProgressJSON {
        return Object.assign(super.toJSON(), this);
    }
}
